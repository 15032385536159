import { default as account_45lookup78lQMGeAKzMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/account-lookup.vue?macro=true";
import { default as addwwaGPaZYsCMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/add.vue?macro=true";
import { default as deletedUNw8nyRlcUMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue?macro=true";
import { default as _91id_93KCEyp8aHzaMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue?macro=true";
import { default as indexhxF8ru5z0XMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93nDhRzcD602Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue?macro=true";
import { default as companiesnllkdMBuGqMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies.vue?macro=true";
import { default as _91id_93ZSnZE2fCsNMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue?macro=true";
import { default as indexj87iRMItGNMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue?macro=true";
import { default as index1fCtIhAHiuMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/configs/index.vue?macro=true";
import { default as dashboardwAA5WJbKEaMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/dashboard.vue?macro=true";
import { default as addDlfDAcmCJlMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/add.vue?macro=true";
import { default as _91id_93zkV0bLb33YMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue?macro=true";
import { default as _91id_93SWfqVoKaKhMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue?macro=true";
import { default as index28hCLu9L3wMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/index.vue?macro=true";
import { default as _91id_93JEg0qypzagMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue?macro=true";
import { default as indexVHY5cL2XDvMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/judges/index.vue?macro=true";
import { default as index7txYKWe9ljMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue?macro=true";
import { default as indexvoQPyu3SAqMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue?macro=true";
import { default as indexhkNAQl8SmxMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/index.vue?macro=true";
import { default as indexCvJigHryg4Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue?macro=true";
import { default as indexQqQuMnoNSvMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue?macro=true";
import { default as logsavQPu18vT5Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs.vue?macro=true";
import { default as add12MbyzpaZ9Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/add.vue?macro=true";
import { default as indee_45active_45membersSJ4JNj33q5Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue?macro=true";
import { default as index3aDoZVtkZ4Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/index.vue?macro=true";
import { default as indexoLNeODF4uMMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue?macro=true";
import { default as _91withId_93RqUryIUi4pMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue?macro=true";
import { default as indexncCrTli6w4Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue?macro=true";
import { default as _91id_93tehrHkhDUmMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue?macro=true";
import { default as resend_45welcome_45emailPZNgdDdWJPMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue?macro=true";
import { default as screener_45listsT0xmoKZZrrMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue?macro=true";
import { default as indexsips2Pb79yMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue?macro=true";
import { default as _91id_93eK75TntNR6Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue?macro=true";
import { default as membersyrNVNUJbI3Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members.vue?macro=true";
import { default as statisticshT2HYobCZHMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue?macro=true";
import { default as _91id_936IOzFXtk0MMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue?macro=true";
import { default as indexMauuPTjx0dMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/payments/index.vue?macro=true";
import { default as addkU7IkzaWo0Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue?macro=true";
import { default as _91id_93aBpd5ZaeXMMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue?macro=true";
import { default as indexjc60t7X9rtMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue?macro=true";
import { default as _91id_93G7sHkXBcUiMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue?macro=true";
import { default as promo_45codeybIuGXoqP3Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code.vue?macro=true";
import { default as screeningsRHCB8ejmfcMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/screenings.vue?macro=true";
import { default as index6BbliXee2AMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/index.vue?macro=true";
import { default as inviteVXPjeqCwZNMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue?macro=true";
import { default as _91code_931Q0jniEOxKMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue?macro=true";
import { default as ticketsLnzmUt6neRMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets.vue?macro=true";
import { default as index7mq5eJIPVRMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue?macro=true";
import { default as dashboardRf9mXFT4bbMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue?macro=true";
import { default as indexH2GFdZ7rkHMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue?macro=true";
import { default as _91invoiceId_93glv8qQM2IVMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue?macro=true";
import { default as addRWBcxS6l1CMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue?macro=true";
import { default as multiple_45addsXFnMehb9lMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue?macro=true";
import { default as indexthENtXzDQvMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue?macro=true";
import { default as companiesuJIq44UnTjMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies.vue?macro=true";
import { default as indexF86rjOVmlNMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue?macro=true";
import { default as forgot_45passwordWctZ41ZgraMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/forgot-password.vue?macro=true";
import { default as indexUnZu8CNgqtMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/index.vue?macro=true";
import { default as invoicesD8YrJoXyZYMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/invoices.vue?macro=true";
import { default as applyL9nxNUxQHGMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/judges/apply.vue?macro=true";
import { default as _91_46_46_46id_93BphH14HhNPMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue?macro=true";
import { default as _91id_93V87ubRl963Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/edit/[id].vue?macro=true";
import { default as indexHm7NOWEHOzMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/membership/index.vue?macro=true";
import { default as indexnIcLiwDX74Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/signup/index.vue?macro=true";
import { default as _91_46_46_46id_93JU0blD7MfQMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/view/[...id].vue?macro=true";
import { default as membersFUXkKYylg9Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members.vue?macro=true";
import { default as indexcVoYwutJRQMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/index.vue?macro=true";
import { default as _91_46_46_46id_939XqGJ6DBUtMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue?macro=true";
import { default as _91id_93YdDT9kuTz8Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue?macro=true";
import { default as indexhKPjyOmVOvMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue?macro=true";
import { default as _91id_93jM2cApIaF6Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/[id].vue?macro=true";
import { default as _91invoiceId_93mG1TWttbPAMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue?macro=true";
import { default as password_45resetQA8E0JAvAzMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/password-reset.vue?macro=true";
import { default as indexhOIk7lZeCZMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/index.vue?macro=true";
import { default as _91id_93mYIkZ8nMnSMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue?macro=true";
import { default as _91id_93UYu0a277mKMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue?macro=true";
import { default as _91id_93EI0cIAF6rGMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/view/[id].vue?macro=true";
import { default as callbackmDRW2F6qoVMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/qb/callback.vue?macro=true";
import { default as logoutglczogAoivMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/redirect/logout.vue?macro=true";
import { default as indexa8H1JaNJYwMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/index.vue?macro=true";
import { default as _91token_93LGHLAjxFVHMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue?macro=true";
import { default as _91token_93oxkNWjA7K8Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue?macro=true";
import { default as _91code_93ANqh7NHcAqMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue?macro=true";
import { default as indexOS4mkKgZKCMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/index.vue?macro=true";
import { default as _91code_93xRE9AIzERqMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue?macro=true";
import { default as _91code_93v5x33wkFfbMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/view/[code].vue?macro=true";
import { default as ticketswm2wm39etFMeta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets.vue?macro=true";
import { default as votessohD8ULh1Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/vote.vue?macro=true";
import { default as component_45stubN5ebdwvlL2Meta } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubN5ebdwvlL2 } from "/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-lookup",
    path: "/account-lookup",
    meta: account_45lookup78lQMGeAKzMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/account-lookup.vue")
  },
  {
    name: companiesnllkdMBuGqMeta?.name,
    path: "/admin/companies",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies.vue"),
    children: [
  {
    name: "admin-companies-add",
    path: "add",
    meta: addwwaGPaZYsCMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/add.vue")
  },
  {
    name: "admin-companies-deleted",
    path: "deleted",
    meta: deletedUNw8nyRlcUMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue")
  },
  {
    name: "admin-companies-edit-id",
    path: "edit/:id()",
    meta: _91id_93KCEyp8aHzaMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue")
  },
  {
    name: "admin-companies",
    path: "",
    meta: indexhxF8ru5z0XMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/index.vue")
  },
  {
    name: "admin-companies-view-id",
    path: "view/:id()",
    meta: _91id_93nDhRzcD602Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue")
  }
]
  },
  {
    name: "admin-company-suggestions-confirm-id",
    path: "/admin/company-suggestions/confirm/:id()",
    meta: _91id_93ZSnZE2fCsNMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue")
  },
  {
    name: "admin-company-suggestions",
    path: "/admin/company-suggestions",
    meta: indexj87iRMItGNMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue")
  },
  {
    name: "admin-configs",
    path: "/admin/configs",
    meta: index1fCtIhAHiuMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/configs/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardwAA5WJbKEaMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/dashboard.vue")
  },
  {
    name: "admin-events-add",
    path: "/admin/events/add",
    meta: addDlfDAcmCJlMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/add.vue")
  },
  {
    name: "admin-events-appeal-id",
    path: "/admin/events/appeal/:id()",
    meta: _91id_93zkV0bLb33YMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue")
  },
  {
    name: "admin-events-edit-id",
    path: "/admin/events/edit/:id()",
    meta: _91id_93SWfqVoKaKhMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue")
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: index28hCLu9L3wMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/index.vue")
  },
  {
    name: "admin-events-view-id",
    path: "/admin/events/view/:id()",
    meta: _91id_93JEg0qypzagMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue")
  },
  {
    name: "admin-judges",
    path: "/admin/judges",
    meta: indexVHY5cL2XDvMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/judges/index.vue")
  },
  {
    name: logsavQPu18vT5Meta?.name,
    path: "/admin/logs",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs.vue"),
    children: [
  {
    name: "admin-logs-card-scan",
    path: "card-scan",
    meta: index7txYKWe9ljMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue")
  },
  {
    name: "admin-logs-email",
    path: "email",
    meta: indexvoQPyu3SAqMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue")
  },
  {
    name: "admin-logs",
    path: "",
    meta: indexhkNAQl8SmxMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/index.vue")
  },
  {
    name: "admin-logs-mailchimp",
    path: "mailchimp",
    meta: indexCvJigHryg4Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue")
  },
  {
    name: "admin-logs-payment",
    path: "payment",
    meta: indexQqQuMnoNSvMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue")
  }
]
  },
  {
    name: membersyrNVNUJbI3Meta?.name,
    path: "/admin/members",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members.vue"),
    children: [
  {
    name: "admin-members-add",
    path: "add",
    meta: add12MbyzpaZ9Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/add.vue")
  },
  {
    name: "admin-members-indee-active-members",
    path: "indee-active-members",
    meta: indee_45active_45membersSJ4JNj33q5Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue")
  },
  {
    name: "admin-members",
    path: "",
    meta: index3aDoZVtkZ4Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/index.vue")
  },
  {
    name: "admin-members-merge-id",
    path: "merge/:id()",
    meta: indexoLNeODF4uMMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue")
  },
  {
    name: "admin-members-merge-id-with-withId",
    path: "merge/:id()/with/:withId()",
    meta: _91withId_93RqUryIUi4pMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue")
  },
  {
    name: "admin-members-merge",
    path: "merge",
    meta: indexncCrTli6w4Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue")
  },
  {
    name: "admin-members-merge-resolve-id",
    path: "merge/resolve/:id()",
    meta: _91id_93tehrHkhDUmMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue")
  },
  {
    name: "admin-members-resend-welcome-email",
    path: "resend-welcome-email",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue")
  },
  {
    name: "admin-members-screener-lists",
    path: "screener-lists",
    meta: screener_45listsT0xmoKZZrrMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue")
  },
  {
    name: "admin-members-student-applications",
    path: "student-applications",
    meta: indexsips2Pb79yMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue")
  },
  {
    name: "admin-members-student-applications-view-id",
    path: "student-applications/view/:id()",
    meta: _91id_93eK75TntNR6Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue")
  }
]
  },
  {
    name: "admin-memberships-statistics",
    path: "/admin/memberships/statistics",
    meta: statisticshT2HYobCZHMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue")
  },
  {
    name: "admin-memberships-update-id",
    path: "/admin/memberships/update/:id()",
    meta: _91id_936IOzFXtk0MMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    meta: indexMauuPTjx0dMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/payments/index.vue")
  },
  {
    name: promo_45codeybIuGXoqP3Meta?.name,
    path: "/admin/promo-code",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code.vue"),
    children: [
  {
    name: "admin-promo-code-add",
    path: "add",
    meta: addkU7IkzaWo0Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue")
  },
  {
    name: "admin-promo-code-edit-id",
    path: "edit/:id()",
    meta: _91id_93aBpd5ZaeXMMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue")
  },
  {
    name: "admin-promo-code",
    path: "",
    meta: indexjc60t7X9rtMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue")
  },
  {
    name: "admin-promo-code-view-id",
    path: "view/:id()",
    meta: _91id_93G7sHkXBcUiMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue")
  }
]
  },
  {
    name: "admin-screenings",
    path: "/admin/screenings",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/screenings.vue")
  },
  {
    name: ticketsLnzmUt6neRMeta?.name,
    path: "/admin/tickets",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets.vue"),
    children: [
  {
    name: "admin-tickets",
    path: "",
    meta: index6BbliXee2AMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-tickets-invite",
    path: "invite",
    meta: inviteVXPjeqCwZNMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue")
  },
  {
    name: "admin-tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_931Q0jniEOxKMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue")
  }
]
  },
  {
    name: "admin-voter-qualifications",
    path: "/admin/voter-qualifications",
    meta: index7mq5eJIPVRMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies.vue"),
    children: [
  {
    name: "companies-manager-dashboard",
    path: "manager/dashboard",
    meta: dashboardRf9mXFT4bbMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue")
  },
  {
    name: "companies-manager-view-id",
    path: "manager/view/:id()",
    meta: indexH2GFdZ7rkHMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue")
  },
  {
    name: "companies-manager-view-id-invoice-invoiceId",
    path: "manager/view/:id()/invoice/:invoiceId()",
    meta: _91invoiceId_93glv8qQM2IVMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue")
  },
  {
    name: "companies-manager-view-id-member-add",
    path: "manager/view/:id()/member/add",
    meta: addRWBcxS6l1CMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue")
  },
  {
    name: "companies-manager-view-id-member-multiple-add",
    path: "manager/view/:id()/member/multiple-add",
    meta: multiple_45addsXFnMehb9lMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue")
  },
  {
    name: "companies-manager-view-id-member-resolve-similar",
    path: "manager/view/:id()/member/resolve-similar",
    meta: indexthENtXzDQvMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue")
  }
]
  },
  {
    name: "events-screening-id",
    path: "/events/screening/:id()",
    meta: indexF86rjOVmlNMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordWctZ41ZgraMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexUnZu8CNgqtMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/invoices.vue")
  },
  {
    name: "judges-apply",
    path: "/judges/apply",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/judges/apply.vue")
  },
  {
    name: "members",
    path: "/members",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members.vue"),
    children: [
  {
    name: "members-addresses-id",
    path: "addresses/:id(.*)*",
    meta: _91_46_46_46id_93BphH14HhNPMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue")
  },
  {
    name: "members-edit-id",
    path: "edit/:id()",
    meta: _91id_93V87ubRl963Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/edit/[id].vue")
  },
  {
    name: "members-membership",
    path: "membership",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/membership/index.vue")
  },
  {
    name: "members-signup",
    path: "signup",
    meta: indexnIcLiwDX74Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/signup/index.vue")
  },
  {
    name: "members-view-id",
    path: "view/:id(.*)*",
    meta: _91_46_46_46id_93JU0blD7MfQMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/view/[...id].vue")
  }
]
  },
  {
    name: "memberships-card",
    path: "/memberships/card",
    meta: indexcVoYwutJRQMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/index.vue")
  },
  {
    name: "memberships-card-print-id",
    path: "/memberships/card/print/:id(.*)*",
    meta: _91_46_46_46id_939XqGJ6DBUtMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue")
  },
  {
    name: "memberships-invoice-id",
    path: "/memberships/invoice/:id()",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue")
  },
  {
    name: "memberships-my-membership",
    path: "/memberships/my-membership",
    meta: indexhKPjyOmVOvMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue")
  },
  {
    name: "memberships-update-id",
    path: "/memberships/update/:id()",
    meta: _91id_93jM2cApIaF6Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/[id].vue")
  },
  {
    name: "memberships-update-invoice-invoiceId",
    path: "/memberships/update/invoice/:invoiceId()",
    meta: _91invoiceId_93mG1TWttbPAMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetQA8E0JAvAzMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/password-reset.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexhOIk7lZeCZMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/index.vue")
  },
  {
    name: "payments-invoice-id",
    path: "/payments/invoice/:id()",
    meta: _91id_93mYIkZ8nMnSMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue")
  },
  {
    name: "payments-receipt-id",
    path: "/payments/receipt/:id()",
    meta: _91id_93UYu0a277mKMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue")
  },
  {
    name: "payments-view-id",
    path: "/payments/view/:id()",
    meta: _91id_93EI0cIAF6rGMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/view/[id].vue")
  },
  {
    name: "qb-callback",
    path: "/qb/callback",
    meta: callbackmDRW2F6qoVMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/qb/callback.vue")
  },
  {
    name: "redirect-logout",
    path: "/redirect/logout",
    meta: logoutglczogAoivMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/redirect/logout.vue")
  },
  {
    name: ticketswm2wm39etFMeta?.name,
    path: "/tickets",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/index.vue")
  },
  {
    name: "tickets-invite-token",
    path: "invite/:token()",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue")
  },
  {
    name: "tickets-invite-payment-token",
    path: "invite/payment/:token()",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue")
  },
  {
    name: "tickets-payment-code",
    path: "payment/:code()",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue")
  },
  {
    name: "tickets-payment",
    path: "payment",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/index.vue")
  },
  {
    name: "tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93xRE9AIzERqMeta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue")
  },
  {
    name: "tickets-view-code",
    path: "view/:code()",
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/view/[code].vue")
  }
]
  },
  {
    name: "vote",
    path: "/vote",
    meta: votessohD8ULh1Meta || {},
    component: () => import("/tmp/codebuild/output/src571/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/vote.vue")
  },
  {
    name: component_45stubN5ebdwvlL2Meta?.name,
    path: "/judge-application",
    component: component_45stubN5ebdwvlL2
  },
  {
    name: component_45stubN5ebdwvlL2Meta?.name,
    path: "/profile",
    component: component_45stubN5ebdwvlL2
  },
  {
    name: component_45stubN5ebdwvlL2Meta?.name,
    path: "/login",
    component: component_45stubN5ebdwvlL2
  },
  {
    name: component_45stubN5ebdwvlL2Meta?.name,
    path: "/join",
    component: component_45stubN5ebdwvlL2
  },
  {
    name: component_45stubN5ebdwvlL2Meta?.name,
    path: "/memberships/my_membership",
    component: component_45stubN5ebdwvlL2
  }
]